import Standard from "../../js/Libraries/Standard";

export default class Accordins extends Standard {
  constructor() {
    super("body");
    if (!super.exist()) return;
    this.els = super.set(".OneAccordion ", true);
    this.hash = window.location.hash;
    if (this.hash === "") {
      this.hash = null;
    } else {
      this.hash = this.hash.substring(1).toLowerCase();
    }
    this.els.forEach((el) => {
      new OneAccordin(el);
    });
  }
}

class OneAccordin {
  constructor(el) {
    this.el = el;
    this.btn = el.querySelector(".accordion-btn");
    this.btn.setAttribute("state", "closed");
    this.content = el.querySelector(".accordion-content-container");
    this.btn.addEventListener("click", () => {
      if (this.btn.getAttribute("state") === "closed") {
        this.content.style.height = `${this.content.scrollHeight + 20}px`;
        this.content.classList.add("active");
        this.btn.innerText = "SEE LESS";

        this.btn.setAttribute("state", "open");
      } else {
        this.content.style.height = `0`;
        this.content.classList.remove("active");
        this.btn.innerText = "SEE MORE";
        this.btn.setAttribute("state", "closed");
      }
    });

    window.addEventListener("resize", () => {
      this.content.style.height = `0`;
      this.content.classList.remove("active");
      this.btn.innerText = "SEE MORE";
      this.btn.setAttribute("state", "closed");
    });
  }
}
