import Standard from "../../js/Libraries/Standard";
import jump from "jump.js";

class Back2Top extends Standard {
  constructor() {
    super("body");
    if (!super.exist()) return;
    this.create();
    this.btn = document.querySelector("#Back2Top .back_btn");
    this.events();
  }

  create() {
    const el = document.createElement("div");
    el.id = "Back2Top";
    el.className = "back-to-top";
    el.innerHTML = `
    <div class="back_btn">
      <i class="fa fa-arrow-up"></i>
    </div>
    `;
    document.body.appendChild(el);
  }

  events() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 600) {
        this.btn.style.display = "flex";
      } else {
        this.btn.style.display = "none";
      }
    });

    this.btn.addEventListener("click", () => {
      jump("body", {
        duration: (distance) => Math.min(Math.abs(distance) / 2, 1500),
      });
    });
  }
}

new Back2Top();
