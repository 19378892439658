export default class Form {
  readLinebreaks(text) {
    return text.replace(/\r?\n/g, "<br />");
  }

  read2Textarea(str) {
    return str.replace(/(<br>|<\/br>|<br \/>)/gim, "\n");
  }

  check_empty(value, length = false) {
    if (value.trim() === "") {
      return false;
    }
    if (value.trim().length < length) {
      return false;
    }

    return true;
  }

  listen_new_input(inputs) {
    inputs.forEach((input) => {
      input.addEventListener("focus", () => input.classList.remove("error"));
    });
  }
  check_valid_email(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.trim()) && email.trim() !== "") {
      return true;
    } else {
      return false;
    }
  }

  check_valid_phone(phone) {
    const re = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
    const size = phone.trim().length;
    if (re.test(phone.trim()) && phone.trim() !== "" && size > 8 && size < 20) {
      return true;
    } else {
      return false;
    }
  }
  // ^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$
  // valid https adress
  validURL(str) {
    var pattern = new RegExp(
      "^(https:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  // images
  validateSize(size, boundary = 1000000) {
    if (size > boundary) {
      return false;
    } else {
      return true;
    }
  }

  validateFormat(
    format,
    validFormat = ["image/jpeg", "image/jpg", "image/png"]
  ) {
    let bool = false;
    validFormat.forEach((each) => {
      if (each === format) {
        bool = true;
      }
    });
    return bool;
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  replaceUmlaute(str) {
    const umlautMap = {
      Ü: "UE",
      Ä: "AE",
      Ö: "OE",
      ü: "ue",
      ä: "ae",
      ö: "oe",
      ß: "ss",
    };
    return str.replace(/ä|ö|ü|ß/gi, function (matched) {
      return umlautMap[matched];
    });
  }

  checkValidPassword(pwd) {
    if (pwd.length < 8) {
      return false;
    } else if (!/[0-9]/.test(pwd)) {
      return false;
    } else if (!/[A-Z]/.test(pwd)) {
      return false;
    } else if (!/[a-z]/.test(pwd)) {
      return false;
    } else {
      return true;
    }
  }

  insertUmlaute(str) {
    var umlautMap = {
      AE: "Ä",
      OE: "Ö",
      UE: "Ü",
      ae: "ä",
      oe: "ö",
      ue: "ü",
    };
    return str.replace(/ae|oe|aa|ue/gi, function (matched) {
      return umlautMap[matched];
    });
  }

  listenToInput(form) {
    const inputs = form.querySelectorAll("input");
    const btn = form.querySelector("button");
    var allFilled;
    inputs.forEach((each) => {
      each.addEventListener("input", isFilled);
    });
    function isFilled() {
      allFilled = true;
      inputs.forEach((each) => {
        if (each.value.trim() === "") {
          allFilled = false;
        }
      });
      if (!allFilled) {
        btn.classList = "btn-off";
      } else {
        btn.classList = "";
      }
    }
  }
}
