import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";
import Form from "../../js/Libraries/Form";
import jump from "jump.js";
class Contact extends Standard {
  constructor() {
    super("#contact");

    if (!super.exist()) return;

    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.form = new Form();
    this.data = {
      firstName: false,
      lastName: false,
      email: false,
      message: false,
    };
    this.text_error = 0;
    this.sentBtn = super.set("#send_btn");
    this.inputs = super.set('input[type="text"]', true);
    this.email = super.set("#email");
    // this.phone = super.set("#phone");
    this.main_error = super.set(".main_error");
    this.law = super.set("#checkbox-law");
    this.textarea = super.set("textarea");
    this.endpoint = super.setEndpoint("pages_api/contact");
    this.events();
  }
  checkEmail() {
    // check valid email
    const email = this.email.value.trim();
    if (!this.form.check_valid_email(email)) {
      this.email.classList.add("error");
      this.data["email"] = false;
      this.text_error += 1;
      this.main_error.innerText = "Please choose a valid Email Address!";
    } else {
      this.email.classList.remove("error");
      this.data["email"] = email;
    }
  }

  checkPhone() {
    const phone = this.phone.value.trim();
    if (!this.form.check_valid_phone(phone)) {
      this.phone.classList.add("error");
      this.data["phone"] = false;
      this.text_error += 1;
      this.main_error.innerText = "Bitte wähle eine gültige Telefonnummer";
    } else {
      this.phone.classList.remove("error");
      this.data["phone"] = phone;
    }
  }
  checkTextarea() {
    const msg = this.form.readLinebreaks(this.textarea.value);
    if (msg === "") {
      this.data["message"] = false;
      this.textarea.classList.add("error");
    } else {
      this.data["message"] = msg;
      this.textarea.classList.remove("error");
    }
  }
  async sent() {
    this.inputs.forEach((each) => {
      if (each.value.trim() === "") {
        each.classList.add("error");
        this.data[each.id] = false;
      } else {
        each.classList.remove("error");
        this.data[each.id] = each.value.trim();
      }
    });

    // this.checkPhone();
    this.checkEmail();
    this.checkTextarea();
    if (super.contains(this.data, false)) {
      this.text_error += 1;
      this.main_error.innerText = "Please fill in all fields";
      return;
    }

    if (this.text_error === 0) {
      this.main_error.innerText = "";
    } else {
      this.text_error = 0;
      return;
    }

    if (!this.law.checked) {
      this.law.parentNode.parentNode.classList.add("error");

      return;
    }
    this.law.parentNode.parentNode.classList.remove("error");

    this.hl.toggle();
    console.log(this.data);
    const res = await this.http.post(this.endpoint, this.data);
    console.log(res);

    await this.hl.wait();
    if (res.status === 200) {
      this.clean_input();
      this.hl.message(
        "We have received your message and will answer you immediately!"
      );
    } else {
      this.hl.message(
        "There are problems with our server right now. Please try it later again."
      );
    }
    return this.hl.toggle();
  }

  clean_input() {
    this.inputs.forEach((each) => {
      each.value = "";
    });
    this.textarea.value = "";
    this.law.checked = false;
  }
  events() {
    this.sentBtn.addEventListener("click", () => this.sent());
    this.inputs.forEach((each) => {
      each.addEventListener("focus", () => each.classList.remove("error"));
    });
    this.textarea.addEventListener("focus", () =>
      this.textarea.classList.remove("error")
    );
  }
}

new Contact();
