import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

class ShowParamMessage extends Standard {
  constructor() {
    super("body");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.http = new ZachiHttp();
    this.message = super.getParam("message");
    this.endpoint = super.setEndpoint("/pages_api/get_content/");
    this.init();
  }

  async init() {
    await this.read();
  }
  async read() {
    if (this.message !== false) {
      const res = await this.http.get(this.endpoint + this.message);
      if (res.status === 200) {
        await this.hl.wait();
        this.hl.message(res.content);
      } else {
        console.log(res);
      }
    }
  }
}

new ShowParamMessage();
