import './ShowParamMessage.js';

import Links from './_Links.js';
import Accordins from './_Accordions.js';
import AllGlider from './_AllGlider.js';

// import "./Cookie";

new Links();

new Accordins();
new AllGlider();
